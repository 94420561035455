import delivery_finder from "../modules/delivery_finder";
import checkout_sub_toggle from "../modules/checkout_sub_toggle";
import applepay from "../modules/applepay";
import "../utils/selector";
import "../config/validateconfig";
import "parsleyjs/dist/parsley";
import "../plugin/request_spinner";
import global from "../global";
import Inputmask from "inputmask/dist/inputmask/inputmask";

/**
 * config/validateconfig: Customize Classnames/Wrappers/Functions
 *
 * The actual rules are located at the input elements
 *
 * Data-Attributes for Input-Fields:
 *
 *  data-validate-required
 *  data-validate-error-message="Error-Message"
 *  data-validate-trigger="focusout"
 *
 *  Documentation: http://parsleyjs.org/
 */
var validatedForm = $("#stepModel");
var _self;

var checkout = {
  init: function() {
    _self = this;
    this.initPaymentSubmitButtons();
    this.paymentToggles();
    this.subFormToggle();
    this.ibanUpdater();
    this.radioList();
    this.selectStore();
    this.triggerToggleAfterReload();
    this.switchLabel();
    this.preselectShippingOption();
    this.preselectAlternativeDeliveryOption();
    this.initListenersVoucherBox();
    this.pressEnterLoyaltyField();
    this.toggleLoyalty();
    this.countryChain();
    this.deliveryLayer();
    this.rememberInputs();
    this.scrollToFormErrors();
    this.registerPhoneMask();
    this.registerIbanMask();
    this.setCountryCode();
    this.optionCollapse();
    this.validateCheckout();
    this.removeBillingProhibitedAddressError();
    this.updateMaskOnEdit();
    this.openCustomerAlreadyExistsNofication();
    this.asyncLoadLoyaltyPoints();
    this.asyncLoadIngridWidget();
    this.setSsnRequiredForKlarna();
    this.copySsnNumberToKlarnaPayment();
    this.fixCheckoutSummaryBox();
    this.resizeSummaryBox();
    this.synchronizeInputs(
      "billingAddressViewModel.invoiceAddress.firstName",
      "shippingAddressViewModel.shippingAddress.firstName"
    );

    this.synchronizeInputs(
      "billingAddressViewModel.invoiceAddress.lastName",
      "shippingAddressViewModel.shippingAddress.lastName"
    );

    this.synchronizeInputs(
      "billingAddressViewModel.invoiceAddress.firstName",
      "shippingAddressViewModel.shippingAddressHermes.firstName"
    );

    this.synchronizeInputs(
      "billingAddressViewModel.invoiceAddress.lastName",
      "shippingAddressViewModel.shippingAddressHermes.lastName"
    );

    this.synchronizeInputs(
      "billingAddressViewModel.invoiceAddress.firstName",
      "shippingAddressViewModel.shippingAddressPackstation.firstName"
    );

    this.synchronizeInputs(
      "billingAddressViewModel.invoiceAddress.lastName",
      "shippingAddressViewModel.shippingAddressPackstation.lastName"
    );

    $(".js-spinner").requestSpinner({ elementType: "form" });
  },

  validateCheckout: function() {
    /**
     *  Due the special requirements, checkout form has own checkout validation config
     */
    var checkoutValidateConfig = $.extend(
      {},
      ParsleyConfig,
      ParsleyConfigCheckout
    );
    validatedForm.parsley(checkoutValidateConfig);
  },

  toggleLoyalty: function() {
    const ssnNumField = document.getElementById("ssnViewModel.ssn");
    const klarnaSsnNumField = document.getElementById(
      "paymentViewModel.optSsn"
    );
    if ($(".js-toggle-loyaltyprogram").is(":checked")) {
      $(
        ".js-magazine-wrapper, .js-birthday-wrapper, .js-loyalty-legal-wrapper, .js-address-lookup"
      ).removeClass("hide");
    }
    $(".js-toggle-loyaltyprogram").on("change", function() {
      if ($(this).is(":checked")) {
        $(
          ".js-magazine-wrapper, .js-birthday-wrapper, .js-loyalty-legal-wrapper, .js-address-lookup"
        )
          .not(".staff-control")
          .removeClass("hide");
        $("#birthday").setAttribute("data-validate-required", "true");
        if (
          klarnaSsnNumField.value.length !== 0 &&
          ssnNumField.value.length === 0
        ) {
          ssnNumField.value = klarnaSsnNumField.value;
        }
      } else {
        ssnNumField.value = "";
        $(
          ".js-magazine-wrapper, .js-birthday-wrapper, .js-loyalty-legal-wrapper, .js-address-lookup"
        )
          .not(".staff-control")
          .addClass("hide");
        $(".js-magazine-wrapper > span").removeClass("checked");
        $(".js-magazine-wrapper > span > input").prop("checked", false);
        $("#birthday").removeAttribute("data-validate-required");
      }
    });
  },

  preselectShippingOption: function() {
    $(document).on(
      "click",
      '[name="shippingOptionsViewModel.deliveryCarrier"]',
      function(e, type) {
        var $wrapper = $(this)
          .closest(".js_toggle-content")
          .next();
        $(document)
          .find('[name^="shippingOptionsViewModel.deliveryType"]')
          .removeProp();
        $wrapper
          .find('[name^="shippingOptionsViewModel.deliveryType"]')
          .first()
          .trigger("click", type);
      }
    );
  },

  preselectAlternativeDeliveryOption: function() {
    $(document).on(
      "click",
      '[name="shippingAddressViewModel.selectedShippingAddressType"]',
      function(e, type) {
        var $wrapper = $(this)
          .closest(".js_toggle-content")
          .next();
        $(document)
          .find('[name^="shippingAddressViewModel.selectedShippingAddressId"]')
          .removeProp();
        $wrapper
          .find('[name^="shippingAddressViewModel.selectedShippingAddressId"]')
          .first()
          .trigger("click", type);
      }
    );
  },

  setSsnRequiredForKlarna: function() {
    let $klarnaRadio = $("#radio-bezahlung-klarna");
    let $ssnInputField = $(".ssn-input-field");
    if ($klarnaRadio) {
      $klarnaRadio.on("change", function() {
        if ($klarnaRadio.is(":checked")) {
          $ssnInputField.attr("data-validate-required", true);
        } else {
          $ssnInputField.attr("data-validate-required", false);
        }
      });
    }
  },

  initListenersVoucherBox: function() {
    const giftCardNumber = document.getElementById(
      "promotionModel.giftCardPhoneNumber"
    );
    const giftcardPin = document.getElementById("promotionModel.pinCode");
    const giftcardBtn = document.getElementById("co-redeemGiftcard");

    if (giftCardNumber) {
      this.addInputListeners(giftCardNumber, [
        {
          event: "focus",
          action: () =>
            $(".checkout-submit-button").each(function() {
              $(this).attr("disabled", true);
            })
        },
        {
          event: "blur",
          action: () =>
            $(".checkout-submit-button").each(function() {
              $(this).attr("disabled", false);
            })
        },
        {
          event: "keyup",
          action: e => {
            e.preventDefault();
            if (e.keyCode === 13) {
              document.getElementById("co-redeemGiftcard").click();
            }
          }
        }
      ]);
    }

    if (giftcardPin) {
      this.addInputListeners(giftcardPin, [
        {
          event: "focus",
          action: () =>
            $(".checkout-submit-button").each(function() {
              $(this).attr("disabled", true);
            })
        },
        {
          event: "blur",
          action: () =>
            $(".checkout-submit-button").each(function() {
              $(this).attr("disabled", false);
            })
        },
        {
          event: "keyup",
          action: e => {
            e.preventDefault();
            if (e.keyCode === 13) {
              document.getElementById("co-redeemGiftcard").click();
            }
          }
        }
      ]);
    }
  },

  addInputListeners: function(inputElement, eventActions) {
    eventActions.forEach(eventAction => {
      inputElement.addEventListener(eventAction.event, eventAction.action);
    });
  },
  pressEnterLoyaltyField: function() {
    var input = document.getElementById("redeemValue");
    if (input) {
      input.addEventListener("focus", function(event) {
        event.preventDefault();
        $(".checkout-submit-button").each(function() {
          $(this).attr("disabled", true);
        });
      });

      input.addEventListener("blur", function(event) {
        event.preventDefault();
        $(".checkout-submit-button").each(function() {
          $(this).attr("disabled", false);
        });
      });

      input.addEventListener("keyup", function(event) {
        event.preventDefault();
        if (event.keyCode === 13) {
          document.getElementById("co-redeem-loyalty-card").click();
        }
      });
    }
  },

  bindForm: function() {
    $(document)
      .find("form")
      .each(function() {
        let $validate = $(this).attr("data-validate");
        if (typeof $validate !== typeof undefined && $validate !== "false") {
          $(this).parsley(validateconfig);
        }
      });
  },

  /*
   *
   */
  radioList: function() {
    var radioList = $(
      '[name="shippingAddressViewModel.selectedShippingAddressId"]'
    );
    radioList.on("change", function() {
      if ($(this).is(radioList[radioList.length - 1])) {
        $(".js-lieferadresse-form").removeClass("hide");
      } else {
        $(".js-lieferadresse-form").addClass("hide");
      }
    });
  },

  rememberInputs: function() {
    var $form = $("#stepModel");
    if ($form.attr("data-submit-form-url") !== undefined) {
      $("input").focusout(function() {
        $.ajax({
          url: $form.attr("data-submit-form-url"),
          method: "POST",
          headers: global.headers,
          data: $form.serialize(),
          success: function(data) {},
          error: function() {}
        });
      });
      $("a").click(function() {
        $.ajax({
          url: $form.attr("data-submit-form-url"),
          method: "POST",
          headers: global.headers,
          data: $form.serialize(),
          success: function(data) {},
          error: function() {}
        });
      });
    }
  },

  selectStore: function() {
    var _this = this;
    $(document).on("click", ".selector.filiale a", function(event) {
      event.preventDefault();
      setTimeout(function() {
        var $storeSelect = $("#selectClickCollectStore");
        var $selectedOption = $storeSelect.find("option:selected");
        var storeId = $selectedOption.attr("data-storeId");

        _this.selectStoreFinished(storeId);
      }, 100);
    });

    $(document).on("click", "#selectClickCollectCityWrapper a", function(
      event
    ) {
      event.preventDefault();
      setTimeout(function() {
        var $titleElement = $("#selectClickCollectStoreNameWrapper").find(
          ".js_selected_name"
        );
        $titleElement.text($titleElement.attr("data-title"));
        _this.updateStoreNameSelection();
        $("#selectClickCollectStoreNameWrapper a")
          .not(".hide")[0]
          .click();
      }, 100);
    });

    $(document).on("click", "#selectClickCollectStoreNameWrapper", function(
      event
    ) {
      if (
        $("#selectClickCollectCity").find("option[selected='selected']").length
      ) {
        _this.updateStoreNameSelection();
      }
    });

    $(document).on("click", "#selectClickCollectStoreNameWrapper a", function(
      event
    ) {
      event.preventDefault();
      setTimeout(function() {
        var $selectedOption = $("#selectClickCollectStoreName").find(
          "option:selected"
        );
        var storeName = $selectedOption.val();
        var city = $selectedOption.attr("data-storeCity");
        var group = $selectedOption.attr("data-storeGroup");
        var storeId = $selectedOption.attr("data-storeId");

        var cityFromSelectedCity = $("#selectClickCollectCity")
          .find("option:selected")
          .val();

        if (city !== cityFromSelectedCity && group !== cityFromSelectedCity) {
          var $titleElement = $("#selectClickCollectCityWrapper").find(
            ".js_selected_name"
          );
          $titleElement.text($titleElement.attr("data-title"));
        }
        _this.selectStoreFinished(storeId);
      }, 100);
    });

    $(document).on("click", ".js-cc-input", function(event) {
      document.querySelector(
        '[data-encrypted-name="number"]'
      ).oninput = function() {
        this.value = _self.cc_format(this.value);
      };
    });
  },

  updateStoreNameSelection: function() {
    var $citySelect = $("#selectClickCollectCity");
    var $selectedOption = $citySelect.find("option:selected");
    var city = $selectedOption.val();

    if (city) {
      $("#selectClickCollectStoreName")
        .find("option")
        .each(function(index) {
          var $fakeOption = $("#selectClickCollectStoreNameWrapper a").eq(
            index
          );
          if (
            $(this).attr("data-storeCity") === city ||
            $(this).attr("data-storeGroup") === city
          ) {
            $fakeOption.removeClass("hide");
          } else {
            $fakeOption.addClass("hide");
          }
        });
    }
  },

  selectStoreFinished: function(storeId) {
    $(".js-filiale-address").each(function() {
      if ($(this).attr("data-storeId") === storeId) {
        $(this).removeClass("hide");
      } else {
        $(this).addClass("hide");
      }
    });
    var $rememberStoreUrl = $("#sub-inputs-lieferadresse-filiale").attr(
      "data-store-submit-url"
    );

    if ($rememberStoreUrl !== undefined) {
      $.ajax({
        url: $rememberStoreUrl,
        method: "POST",
        headers: global.headers,
        data: {
          store: storeId
        }
      });
    }
  },

  /*
   *
   */
  triggerToggleAfterReload: function() {
    $.each($(document).find("input:checked"), function() {
      $(this).trigger("change");
    });
  },

  /*
   *
   */
  subFormToggle: function() {
    var toggleWrapper = $('[id^="sub-inputs-"]');
    toggleWrapper.each(function() {
      var toggleWrap = $(this),
        currentId = toggleWrap.attr("id"),
        toggleCheckboxId = currentId.replace("sub-inputs-", "");
      var radioElement = $('[id^="radio"][id$="' + toggleCheckboxId + '"]');
      checkout_sub_toggle.subFormToggleElement(
        radioElement,
        toggleWrap,
        validatedForm
      );
    });
  },

  initPaymentSubmitButtons: function() {
    let $checkoutSubmitButtons = $(".checkout-submit-button");
    let paymentOption = $("#checkout-payment-type-box")
      .find(".js-design-radio:checked")
      .attr("id");
    if (
      applepay.applePayAvailable() &&
      typeof paymentOption !== "undefined" &&
      paymentOption.includes("applepay")
    ) {
      applepay.displayApplePayButton();
      $checkoutSubmitButtons.each(function() {
        $(this).hide();
      });
    } else {
      applepay.hideApplePayButton();
      $checkoutSubmitButtons.each(function() {
        $(this).show();
      });
    }
  },

  paymentToggles: function() {
    let $checkoutSubmitButtons = $(".checkout-submit-button");
    $("#checkout-payment-type-box")
      .find(".js-design-radio")
      .on("click", function() {
        let isApplePayOption = $(this)
          .attr("id")
          .includes("applepay");
        if (isApplePayOption) {
          $checkoutSubmitButtons.each(function() {
            $(this).hide();
          });
          if (applepay.applePayAvailable()) {
            applepay.displayApplePayButton();
          } else {
            applepay.hideApplePayButton();
            applepay.displayErrorMessage();
          }
        } else {
          applepay.hideApplePayButton();
          applepay.hideErrorMessage();
          $checkoutSubmitButtons.each(function() {
            $(this).show();
          });
        }
      });
  },

  /*
   * triggers a click on the open Link if initial data not yet filled,
   * all lightcase action is handled in delivery_finder module
   */
  deliveryLayer: function() {
    $(".lightcase.dhl .js-design-radio").on("change", function() {
      //name is important e.g Packstation 123 => identifier
      if (!$(".js-packstation-name-hidden").val() && $(this).is(":checked")) {
        delivery_finder.displayDhlFinder();
      }
    });
    $(".lightcase.hermes .js-design-radio").on("change", function() {
      //number is important e.g 123 => identifier
      if (!$(".js-hermesShop-number-hidden").val() && $(this).is(":checked")) {
        delivery_finder.displayHermesFinder();
      }
    });
  },

  /*
   *
   */
  ibanUpdater: function() {
    var downed = 0;
    $("#iban-input").on("keydown", function() {
      downed++;
      if (downed == 3) {
        this.value = this.value.replace(/(\d{4}\s*)/g, function(match) {
          if (match.match(/\s/)) {
            return match;
          }
          return match + " ";
        });

        downed = 0;
      }
    });
  },

  switchLabel: function() {
    var switchLabel = (function() {
      var app = {};

      /**
       * @param {Object}
       */
      (app.textInsertion = function(data) {
        data.target.text(data.labels[data.index]);
        data.target
          .next("input")
          .attr("placeholder", data.labels[data.index].replace("*", ""));
      }),
        (app.getLabels = function(elem) {
          return elem.attr("data-label").split(",");
        }),
        /**
         * @param elem
         * @param indexVal
         */ (app.replace = function(elem, indexVal) {
          var target = elem.attr("data-textswitch"),
            firstname = $("form").find(
              '.firstname-switch[data-textswitch="' + target + '"]'
            ),
            lastname = $("form").find(
              '.lastname-switch[data-textswitch="' + target + '"]'
            ),
            firstNameLabels = app.getLabels(firstname),
            lastNameLabels = app.getLabels(lastname);

          app.textInsertion({
            target: firstname,
            labels: firstNameLabels,
            index: indexVal
          });

          app.textInsertion({
            target: lastname,
            labels: lastNameLabels,
            index: indexVal
          });
        }),
        /**
         * @param elem
         */ (app.init = function(elem) {
          var elem = elem.parents(".js_text-switch-item").find("select");
          var selectedOption = elem.find("option:selected");

          switch (selectedOption.hasClass("js_text-switch")) {
            case true:
              app.replace(elem, 0);
              break;
            case false:
              app.replace(elem, 1);
              break;
          }
        });
      return app;
    })();

    /**
     * Fake-Select Click Event
     */
    $(document).on("click", ".selector.js_text-switch-item a", function() {
      switchLabel.init($(this));
    });

    /**
     * Set labels on load
     */
    $(".js_text-switch-item")
      .find("select")
      .each(function() {
        switchLabel.init($(this));
      });
  },

  countryChain: function() {
    var $targetInput = $("input.country-clone");
    var changeCountries = function() {
      var $selectedCountryOption = $(
        '[name="billingAddressViewModel.invoiceAddress.country"]'
      ).find("option:selected");
      $(".display-shipping-country").text($selectedCountryOption.text());
      $targetInput.attr("value", $selectedCountryOption.val());
    };
    $(document).on("click", ".selector.country a", changeCountries);
    changeCountries();
  },

  /**
   *
   * @param source
   * @param destination
   */
  synchronizeInputs: function(source, destination) {
    $('[name="' + source + '"]').blur(function() {
      var targetElement = $('[name="' + destination + '"]');
      if (targetElement.val().length) {
        return false;
      }
      targetElement.val($(this).val());
    });
  },

  scrollToFormErrors: function() {
    if ($("form input").hasClass("error")) {
      var tmpOffset = $("input.error:visible:first").offset();
      if (typeof tmpOffset !== "undefined") {
        $("html, body").animate(
          {
            scrollTop: $("input.error:visible:first").offset().top - 25
          },
          300
        );
      }
    }
  },

  registerPhoneMask: function() {
    let selectorPhoneMask = document.querySelector(".phone-mask");

    if (selectorPhoneMask) {
      Inputmask("+nn999[99999999999999]", {
        placeholder: " ",
        showMaskOnFocus: false,
        showMaskOnHover: false,
        undoOnEscape: false,
        definitions: {
          n: {
            validator: "[1-9]"
          },
          "+": {
            validator: "[+]"
          }
        }
      }).mask(selectorPhoneMask);
    }
  },

  setCountryCode: function() {
    var selectedCountryCode = $(
      "#billingAddressViewModel\\.invoiceAddress\\.country"
    )
      .find("option:selected")
      .val();
    var currentCountryCode = $(
      "#billingAddressViewModel\\.invoiceAddress\\.phoneNumberCountryCode"
    ).val();
    if (!currentCountryCode && typeof jsonPredialCodeCountry !== "undefined") {
      $(
        "#billingAddressViewModel\\.invoiceAddress\\.phoneNumberCountryCode"
      ).val(jsonPredialCodeCountry[selectedCountryCode]);
    }
  },

  optionCollapse: function() {
    $(document).on("click", ".js-open-options", function() {
      $(this)
        .parent()
        .toggleClass("collapsed");
      $(this)
        .closest(".field-options")
        .toggleClass("hide");
    });
  },

  removeBillingProhibitedAddressError: function() {
    $(document).on("change", "#radio-lieferadresse-rechnungsadresse", function(
      event
    ) {
      if (!$(event.target).is(":checked")) {
        $(".parsley-prohibited_billing_zipcode").text("");
        $(".parsley-prohibited_billing_city").text("");
        $("[data-validate-prohibited_billing_zipcode]").removeClass("error");
        $("[data-validate-prohibited_billing_city]").removeClass("error");
      } else {
        $("[data-validate-prohibited_billing_zipcode]")
          .parsley()
          .validate();
        $("[data-validate-prohibited_billing_city]")
          .parsley()
          .validate();
      }
    });
  },

  cc_format: function(value) {
    var v = value.replace(/\s+/g, "").replace(/[^0-9]/gi, "");
    var matches = v.match(/\d{4,16}/g);
    var match = (matches && matches[0]) || "";
    var parts = [];
    for (var i = 0; i < match.length; i += 4) {
      parts.push(match.substring(i, i + 4));
    }
    if (parts.length) {
      return parts.join(" ");
    } else {
      return value;
    }
  },

  registerIbanMask: function() {
    let selectorSepaCardnumber = document.querySelector(".js-sepa-cardnumber");

    if (selectorSepaCardnumber) {
      Inputmask(
        "aa(99|XX) (9999)|(XXXX) (9999)|(XXXX) (9999)|(XXXX) (9999|XX99) 99",
        {
          placeholder: " ",
          showMaskOnFocus: false,
          showMaskOnHover: false,
          undoOnEscape: false,
          casing: "upper",
          definitions: {
            X: {
              validator: "[xX]"
            }
          }
        }
      ).mask(selectorSepaCardnumber);
    }
  },

  /**
   *  On page load, iban should be able to mask a value including X because of a hidden value sent from server, e.g: DEXXXXXXXXXXXXXXXX3000.
   *  But user should not be able to type X so the mask needs to be updated once the user focuses the input field.
   */
  updateMaskOnEdit: function() {
    var ibanInputElm = $(".js-sepa-cardnumber");
    var ibanHiddenValueFromServer = ibanInputElm.attr("value");
    ibanInputElm.on("click", function() {
      ibanInputElm.off("click");
      Inputmask.remove(ibanInputElm);
      ibanHiddenValueFromServer.indexOf("x") > -1 ||
      ibanHiddenValueFromServer.indexOf("X") > -1
        ? ibanInputElm.val("DE")
        : ibanInputElm.val("");

      Inputmask("aa99 9999 9999 9999 9999 99", {
        placeholder: " ",
        showMaskOnFocus: false,
        showMaskOnHover: false,
        undoOnEscape: false,
        casing: "upper"
      }).mask(ibanInputElm);
    });
  },

  openCustomerAlreadyExistsNofication: function() {
    var customerExistsNotificationDiv = $("#customer-exists-notification");
    if (customerExistsNotificationDiv.length) {
      var ajaxUrl = customerExistsNotificationDiv.attr("data-post-url");
      var email = $("#billingAddressViewModel\\.userData\\.email").val();
      $.ajax({
        url: ajaxUrl,
        method: "POST",
        data: {
          email: email
        },
        dataType: "json",
        success: function(data) {
          $("[data-optuser-firstname]").text(data.firstname);
          $("[data-optuser-lastname]").text(data.lastname);
          $("[data-optuser-email]").text(data.email);
          $("[data-optuser-customerId]").val(data.customerId);

          var parentContainer = document.getElementById(
            "optuser-attribute-parent"
          );
          for (var attr in data.attributes) {
            if (data.attributes.hasOwnProperty(attr) && data.attributes[attr]) {
              var containerElm = document.createElement("div");
              containerElm.classList.add("optuser-attribute-container");
              var headlineDivElm = document.createElement("div");
              //headlineDivElm.classList.add('message-head', 'headline3');
              var displayNameSpanElm = document.createElement("span");
              var displayNameTextNode = document.createTextNode(attr);
              displayNameSpanElm.appendChild(displayNameTextNode);
              var displayValueSpanElm = document.createElement("span");
              var displayValueTextNode = document.createTextNode(
                data.attributes[attr]
              );
              displayValueSpanElm.appendChild(displayValueTextNode);
              var lineBreakElem = document.createElement("br");
              var secondLineBreakElem = document.createElement("br");
              headlineDivElm.appendChild(displayNameSpanElm);
              containerElm.appendChild(headlineDivElm);
              containerElm.appendChild(displayValueSpanElm);
              containerElm.appendChild(lineBreakElem);
              containerElm.appendChild(secondLineBreakElem);
              parentContainer.appendChild(containerElm);
            }
          }
          lightcase.start({
            href: "#customer-exists-notification"
          });
        },
        error: lightcase.start({
          href: "#customer-exists-notification-with-error"
        })
      });
    }
  },

  asyncLoadLoyaltyPoints: function() {
    $.ajax({
      type: "GET",
      url: window.iShop.config.baseUrl + "/ajax/checkout/showLoyaltyAmount/",
      headers: global.headers,
      success: function(data) {
        let result = document.querySelector("#discount-redemption");
        if (result != null) {
          result.innerHTML = data;
        }
      },
      error: function(xhr, status, error) {
        var errorMessage = xhr.status + ": " + xhr.statusText;
        console.log("Error - " + errorMessage);
      }
    });
  },

  fixCheckoutSummaryBox: function() {
    const summaryBox = document.getElementById("checkout-summary-box");
    if (summaryBox) {
      let elementRect = document
        .getElementById("checkout-summary-box-parent")
        .getBoundingClientRect();
      let scrollThreshold = elementRect.top;
      window.addEventListener("scroll", function() {
        if (window.innerWidth > 1024) {
          if (scrollThreshold < 0) {
            elementRect = document
              .getElementById("checkout-summary-box-parent")
              .getBoundingClientRect();
            scrollThreshold = elementRect.top;
          }
          elementRect = document
            .getElementById("checkout-summary-box-parent")
            .getBoundingClientRect();
          scrollThreshold = elementRect.top;
          const scrollTop = window.pageYOffset;
          if (scrollTop > scrollThreshold && scrollThreshold < 0) {
            summaryBox.style.width =
              document
                .getElementById("checkout-summary-box-parent")
                .getBoundingClientRect()
                .width.toString() + "px";
            summaryBox.classList.add("summary-fixed");
          } else if (scrollThreshold >= 0) {
            summaryBox.style.width =
              document
                .getElementById("checkout-summary-box-parent")
                .getBoundingClientRect()
                .width.toString() + "px";
            summaryBox.classList.remove("summary-fixed");
          } else {
            summaryBox.style.width =
              document
                .getElementById("checkout-summary-box-parent")
                .getBoundingClientRect()
                .width.toString() + "px";
            summaryBox.classList.remove("summary-fixed");
          }
        } else {
          summaryBox.style.removeProperty("width");
        }
      });
    }
  },
  resizeSummaryBox: function() {
    const checkoutSummaryBoxParent = document.getElementById(
      "checkout-summary-box"
    );
    if (checkoutSummaryBoxParent) {
      const deviceAgent = navigator.userAgent.toLowerCase();
      if (!deviceAgent.match(/(iphone|ipod|ipad|android|blackberry|symbian)/)) {
        const summaryBoxTop = document.getElementById("summary-top");
        const summaryHeadline = document.getElementById("summary-headline");
        const headerandNavi = document.getElementById("header-and-navi");
        const siteWideBanner = document.getElementById("site-wide-banner");
        var siteWideBannerHeight =
          siteWideBanner != null ? siteWideBanner.offsetHeight : 0;
        var maxHeight =
          checkoutSummaryBoxParent.offsetHeight +
          headerandNavi.offsetHeight +
          siteWideBannerHeight;
        if (maxHeight > window.innerHeight) {
          var heightOffset = maxHeight - window.innerHeight;
          var updatedHeightBoxTop =
            summaryBoxTop.offsetHeight -
            heightOffset -
            summaryHeadline.offsetHeight;
          summaryBoxTop.classList.add("fixed");
          summaryBoxTop.style.height = updatedHeightBoxTop.toString() + "px";
        } else {
          if (summaryBoxTop.classList.contains("fixed")) {
            summaryBoxTop.classList.remove("fixed");
            summaryBoxTop.style.removeProperty("height");
          }
        }
      }
    }
  },

  copySsnNumberToKlarnaPayment: function() {
    const ssnNumField = document.getElementById("ssnViewModel.ssn");
    if (ssnNumField) {
      ssnNumField.addEventListener("keyup", function() {
        const klarnaSsnNumField = document.getElementById(
          "paymentViewModel.optSsn"
        );
        klarnaSsnNumField.value = ssnNumField.value;
      });
    }
  },

  asyncLoadIngridWidget: function() {
    let ingridWidget = document.querySelector("#ingrid-widget");
    if (ingridWidget) {
      function replaceScriptNode(node) {
        if (isScriptNode(node) && !isExternalScript(node)) {
          node.parentNode.replaceChild(cloneScriptNode(node), node);
        } else {
          var i = 0,
            children = node.childNodes;
          while (i < children.length) {
            replaceScriptNode(children[i++]);
          }
        }
        return node;
      }

      function isScriptNode(node) {
        return node.tagName === "SCRIPT";
      }

      function isExternalScript(node) {
        return !!node.src && node.src !== "";
      }

      function cloneScriptNode(node) {
        var script = document.createElement("script");
        script.text = node.innerHTML;
        for (var i = node.attributes.length - 1; i >= 0; i--) {
          script.setAttribute(
            node.attributes[i].name,
            node.attributes[i].value
          );
        }
        return script;
      }

      let shipWallet = document.querySelector("#shipwallet-container");
      if (!shipWallet) {
        function requestWidget() {
          $.ajax({
            type: "POST",
            url: window.iShop.config.baseUrl + "/ajax/fetch/widget/",
            headers: global.headers,
            data: $("form").serialize(),
            success: function(data) {
              if (ingridWidget != null) {
                setTimeout(function() {
                  document
                    .querySelector("#ingrid-spinner")
                    .classList.remove("loader");
                  ingridWidget.classList.remove("hide");
                }, 1500);

                ingridWidget.innerHTML = data;
                replaceScriptNode(ingridWidget);
              }
            },
            error: function(xhr, status, error) {
              document
                .querySelector("#ingrid-spinner")
                .classList.remove("loader");
              document.querySelector("#default-shipping-selection").style = "";
              document.querySelector("#default-carrier-selection").style = "";
              const errorMessage = xhr.status + ": " + xhr.statusText;
              console.log("Error - " + errorMessage);
            }
          });
        }

        requestWidget();
        $("form").on("change", function() {
          // requestWidget()
          window._sw(api => api.suspend());
          $.ajax({
            type: "POST",
            url: window.iShop.config.baseUrl + "/ajax/update/widget/",
            headers: global.headers,
            data: $("form").serialize(),
            success: function() {},
            error: function(xhr, status, error) {
              const errorMessage = xhr.status + ": " + xhr.statusText;
              console.log("Error - " + errorMessage);
            }
          });
        });
      }
    }
  }
};
export default checkout;
