import checkout_sub_toggle from "../modules/checkout_sub_toggle";
import checkout from "../modules/checkout";
import "../utils/selector";
import "../modules/checkout";
import global from "../global";
import applepay from "../modules/applepay";

var jsonObj,
  _self,
  hideClass = "hide",
  pendingRequest = {},
  BLOCK_IDENTIFIER = "[data-checkout-block]",
  DISCOUNT_CONTAINER_CLASS = ".discount-redemption",
  form = $("#stepModel");

var dependencies = {
  init: function() {
    _self = this;
    $(document).on("selectchange", ".selector.country", function(event) {
      var optBillingCountryChangeUrl = $(this).attr("data-billing-country-url");
      var countryCode = $(this)
        .find("option:selected")
        .val();
      if (optBillingCountryChangeUrl && countryCode) {
        //          We must change the country via Ajax call because of possible Free-Shipping Promotions which depends on country.
        //          This promotion is normally bound to a customer Segment which is evaluated by request interceptor.
        //          Changing country and evaluation by PE on single request is not possible so we must use an extra request to store new country information
        $.ajax({
          url: optBillingCountryChangeUrl,
          method: "POST",
          headers: global.headers,
          data: { countryIso2: countryCode },
          dataType: "text",
          beforeSend: function() {
            _self.fetchingScreen(true);
          },
          success: function(jsonData) {
            if (jsonData.hasOwnProperty("zipCodeRegex")) {
              var newRegex = jsonData["zipCodeRegex"];
              if (newRegex) {
                var $zipCodeInput = $(
                  "#shippingZipCode, #checkoutBillingZipCode"
                );
                $.each($zipCodeInput, function() {
                  $(this).attr("data-validate-pattern", newRegex);
                  if ($(this).val() != "") {
                    $(this)
                      .parsley()
                      .validate();
                  }
                });
              }
            }
            _self.requestDependencies(true);
          }
        });
      } else {
        _self.requestDependencies(false);
      }
      if (typeof jsonPredialCodeCountry !== "undefined") {
        $(
          "#billingAddressViewModel\\.invoiceAddress\\.phoneNumberCountryCode"
        ).val(jsonPredialCodeCountry[countryCode]);
      }
    });

    $(document).on("click", ".js-redeem-discount", function(event) {
      event.preventDefault();
      var button = $(this);
      var url = $(this).attr("data-binding-url");
      const voucherMessageBox = document.querySelector("#voucher-msg-box");

      $.ajax({
        url: url,
        method: "POST",
        headers: global.headers,
        data: form.serialize(),
        beforeSend: function() {
          button.addClass("animate").prop("disabled", true);
        },
        success: function(response) {
          document.querySelector(
            ".remote-feedback-message"
          ).innerHTML = response;
          if (voucherMessageBox) {
            document.querySelector("#voucher-msg-box").innerHTML = "";
          }
          _self.requestDependencies(false, {});
          button.removeClass("animate").prop("disabled", false);
        },
        error: function(e) {
          console.log(e.message);
          button.removeClass("animate").prop("disabled", false);
          return false;
        }
      });
    });

    $(document).on("click", ".js-delete-item", function() {
      var deletedPosition = $(this).closest(".js-delete-position");
      deletedPosition.addClass("deleting");
      var ajaxUrl = $(this).attr("data-target");
      $.ajax({
        url: ajaxUrl,
        cache: false
      })
        .success(function() {
          deletedPosition.slideUp(function() {
            deletedPosition.hide();
            _self.fetchingScreen(true);
            window.location.href = window.location.href;
            $(this).removeClass("loading");
          });
        })
        .fail(function(error) {
          if (window.console) {
            window.console.log(error);
          }
        });
    });

    $(document).on(
      "click selectchange",
      ".js_depends, .js_depends_on_change",
      function(event, type) {
        if (type != "noRefresh" || type === "undefined") {
          var blockType = $(this).closest(BLOCK_IDENTIFIER);
          setTimeout(function() {
            _self.requestDependencies(false, blockType);
          }, 100);
        }
      }
    );

    $(document).on(
      "change",
      "input.js-design-radio.se-shipping-address-new, #radio-label-lieferadresse-abweichend",
      function() {
        var anotherDeliveryAddress = $("#radio-lieferadresse-abweichend")[0];
        var anotherDeliveryAddressNew = $(
          "#sub-inputs-lieferadresse-abweichend"
        );
        if (
          !anotherDeliveryAddressNew.checked ||
          !anotherDeliveryAddress.checked
        ) {
          anotherDeliveryAddressNew.checked = false;
          $(
            "#sub-inputs-lieferadresse-abweichend [data-validate-required]"
          ).each(function(i, obj) {
            var jqObj = jQuery(obj);
            jqObj.attr("data-validate-required", false);
          });
        } else {
          $(
            "#sub-inputs-lieferadresse-abweichend [data-validate-required]"
          ).each(function(i, obj) {
            var jqObj = jQuery(obj);
            jqObj.attr("data-validate-required", true);
          });
        }
      }
    );
    let ingridWidget = document.querySelector("#ingrid-widget");
    if (ingridWidget) {
      setTimeout(function() {
        window._sw(function(api) {
          api.on("data_changed", function(data, meta) {
            if (meta.price_changed) {
              _self.requestDependencies(false, null, data.price);
            }
            if (meta.delivery_type_changed) {
              let ingridField = document.getElementById("ingridError");
              let globalField = document.getElementById("stepModel.errors");
              if (ingridField) {
                ingridField.remove();
              }
              if (globalField) {
                globalField.remove();
              }
            }
          });
        });
      }, 3000);
    }
    $(document).on("fetchDeps", function() {
      _self.requestDependencies(false);
    });

    $(document).on("click", ".js-remove-code", function() {
      _self.removeVoucherCode(this);
    });

    $(document).on("click", ".js-add-membership", function() {
      event.preventDefault();
      var button = $(this);
      var url = $(this).attr("data-target");
      var membershipId;
      var memberCheckbox;
      if (url.includes("scout")) {
        membershipId = $("#scoutId");
        memberCheckbox = $("#scoutMember");
      } else if (url.includes("suomen")) {
        membershipId = $("#suomenId");
        memberCheckbox = $("#suomenMember");
      }
      $.ajax({
        url: url,
        method: "POST",
        headers: global.headers,
        contentType: "application/json",
        data: membershipId.val(),
        beforeSend: function() {
          button.addClass("animate").prop("disabled", true);
        },
        success: function(response) {
          if (response.success) {
            _self.requestDependencies(true);
            membershipId.prop("disabled", true);
          } else {
            button.removeClass("animate");
            membershipId.val("");
            membershipId.prop("disabled", true);
            memberCheckbox.parent().removeClass("checked");
          }
        },
        error: function(e) {
          button.removeClass("animate");
          membershipId.val("");
          membershipId.prop("disabled", true);
          memberCheckbox.parent().removeClass("checked");
          return false;
        }
      });
    });

    $(document).on("click", ".js-toggle-scout-membership", function() {
      let scoutId = $("#scoutId");
      let addScoutMembership = $("#addScoutMembership");
      scoutId.prop("disabled", !this.checked);
      addScoutMembership.prop("disabled", !this.checked);
      if (!this.checked) {
        let basketTrashIcons = $(".js-basket-trash-icon");
        let trashIcon;
        for (let x of basketTrashIcons) {
          if (x.getAttribute("data-target").includes("scoutupdate")) {
            trashIcon = x;
            break;
          }
        }
        if (trashIcon) {
          _self.removeVoucherCode(trashIcon);
        } else {
          scoutId.prop("disabled", !this.checked);
          addScoutMembership.prop("disabled", !this.checked);
          $("#scout-membership span").addClass("checked");
        }
      }
      $("#stepModel")
        .parsley()
        .refresh();
    });

    $(document).on("click", ".js-toggle-suomen-membership", function() {
      let suomenId = $("#suomenId");
      let addSuomenMembership = $("#addSuomenMembership");
      suomenId.prop("disabled", !this.checked);
      addSuomenMembership.prop("disabled", !this.checked);
      if (!this.checked) {
        let basketTrashIcons = $(".js-basket-trash-icon");
        let trashIcon;
        for (let x of basketTrashIcons) {
          if (x.getAttribute("data-target").includes("suomenupdate")) {
            trashIcon = x;
            break;
          }
        }
        if (trashIcon) {
          _self.removeVoucherCode(trashIcon);
        } else {
          suomenId.prop("disabled", !this.checked);
          addSuomenMembership.prop("disabled", !this.checked);
          $("#suomen-membership span").addClass("checked");
        }
      }
      $("#stepModel")
        .parsley()
        .refresh();
    });

    $(document).ready(function() {
      let scoutId = $("#scoutId");
      let scoutMember = $("#scoutMember");
      let addScoutMembership = $("#addScoutMembership");
      let suomenId = $("#suomenId");
      let suomenMember = $("#suomenMember");
      let addSuomenMembership = $("#addSuomenMembership");
      if (scoutId.length) {
        if (scoutId.val()) {
          scoutMember.trigger("click");
        }
        scoutId.prop("disabled", true);
        addScoutMembership.prop("disabled", true);
      }
      if (suomenId.length) {
        if (suomenId.val()) {
          suomenMember.trigger("click");
        }
        suomenId.prop("disabled", true);
        addSuomenMembership.prop("disabled", true);
      }
    });
    _self.requestDependencies(false);
  },

  /**
   * Ajax-Request to receive JSON with Checkout-State to apply to DOM
   */
  requestDependencies: function(updatePayment, blockType, deliveryCost) {
    if (pendingRequest && typeof pendingRequest.abort === "function") {
      try {
        pendingRequest.abort();
      } catch (err) {
        console.log(err);
      }
    }

    var xhr = $.ajax({
      url: form.attr("data-post-url"),
      method: "POST",
      headers: global.headers,
      data:
        form.serialize() +
        "&updatePayment=" +
        updatePayment +
        "&deliveryCost=" +
        deliveryCost,
      dataType: "text",
      beforeSend: function() {
        _self.fetchingScreen(true, blockType);
      },
      success: function(data) {
        if (_self._isJson(data)) {
          var jsonObjAll = $.parseJSON(data);
          var zipCodeRegex = jsonObjAll.zipCodeRegex["zipCodeRegex"];
          $("#checkoutBillingZipCode").attr(
            "data-validate-pattern",
            zipCodeRegex
          );
          jsonObj = jsonObjAll.dependencies;
        }
        if (jsonObjAll.hasOwnProperty("paymentBox")) {
          _self.setPaymentTypes(jsonObjAll.paymentBox);
        }
        _self.setShippingDeps();
        _self.setCarrierDeps();
        _self.setPaymentMethod();
        _self.setSummary(jsonObjAll.summaryMarkup);
        _self.setSubmitInfo(jsonObj.payment.stateValues);
        _self.fetchingScreen(false);
        _self.setBasketHash(jsonObjAll["basketHash"]);
        _self.fixSummaryBottom();
      },
      error: function() {
        _self.fetchingScreen(false);
        return false;
      }
    });

    pendingRequest = xhr;
    xhr;
  },

  setSubmitInfo: function(paymentStateValues) {
    let selectPaymentBox = document.querySelectorAll(
      "#checkout-payment-type-box > .js_toggle-content > span.checked input"
    )[0];
    let selectedPaymentValue;
    if (selectPaymentBox) {
      selectedPaymentValue = selectPaymentBox.value;
    }
    let messageNode = $("#submitMessage");
    if (
      selectedPaymentValue &&
      paymentStateValues[selectedPaymentValue].paymentProviderNeeded
    ) {
      messageNode.removeClass(hideClass);
    } else {
      messageNode.addClass(hideClass);
    }
  },

  setShippingDeps: function() {
    _self._isModified(jsonObj.shipping.modified);
    _self.applyDependencies(
      jsonObj.shipping.stateValues,
      "js_shipping-options"
    );
  },

  setCarrierDeps: function() {
    _self._isModified(jsonObj.carrier.modified);
    _self.applyDependencies(jsonObj.carrier.stateValues, "js_carrier-options");
    _self.applyDependenciesSpecialOptions(
      jsonObj.carrier.specialOptions,
      "specialOptions",
      "js_carrier-options"
    );
  },

  setPaymentTypes: function(paymentBox) {
    $("#checkout-payment-type-box").replaceWith($(paymentBox).html());
    $("#checkout-payment-type-box .selector").SnsSelector();
    $("#checkout-payment-type-box .js-design-checkbox").SnsDesignCheckbox();
    $('input[id^="radio-bezahlung-"]').each(function() {
      var radioElement = $(this);
      var radioId = radioElement.attr("id").replace("radio", "#sub-inputs");
      var subToggleElement = $(radioId);
      radioElement.SnsDesignRadio();
      checkout_sub_toggle.subFormToggleElement(
        radioElement,
        subToggleElement,
        form
      );
      if (radioElement.is(":checked")) {
        subToggleElement.removeClass(hideClass);
      }
      $(document).change(function() {
        if (radioElement.is(":checked")) {
          subToggleElement.removeClass(hideClass);
        }
      });
    });
    form.parsley().refresh();
  },

  setSummary: function(markup) {
    $("#summary-cart").replaceWith($(markup));
  },

  fixSummaryBottom: function() {
    checkout.resizeSummaryBox();
  },
  setPaymentMethod: function() {
    _self._isModified(jsonObj.payment.modified);
    _self.applyDependencies(jsonObj.payment.stateValues, "js_payment-options");
  },

  /**
   *
   * @param modified
   * @returns {boolean}
   */
  _isModified: function(modified) {
    if (modified === false) {
      return false;
    }
  },

  /**
   *
   * @param str
   * @returns {boolean}
   */
  _isJson: function(str) {
    try {
      JSON.parse(str);
    } catch (e) {
      return false;
    }
    return true;
  },

  _uncheckHideOption: function(input) {
    input.prop("checked", false);
    input.closest(".design-radio").removeClass("checked");
    input.closest(".js_toggle-content").addClass(hideClass);
  },

  _uncheckHideOptionCheckBox: function(input) {
    input.prop("checked", false);
    input.closest(".design-checkbox").removeClass("checked");
    input.closest(".js_toggle-content").addClass(hideClass);
  },

  _setOptionFee: function(type, value, container) {
    var shippingCarrier = container.find('[data-option-fee="' + type + '"]');
    shippingCarrier.html(value);
  },

  _setOptionDescription: function(key, value, container) {
    if (typeof value === "undefined") {
      return false;
    }

    var optionDescription = container
      .find('[value="' + key + '"]')
      .closest(".js_toggle-content");
    optionDescription.find("label span").text(value);
  },

  setBasketHash: function(hash) {
    $("#basketHash").val(hash);
  },

  _autoSelectOption: function(input) {
    input.trigger("click", "noRefresh");
  },

  _showOption: function(input) {
    input.closest(".js_toggle-content").removeClass(hideClass);
  },

  /**
   *
   * @param value
   * @param section
   */
  applyDependenciesSpecialOptions: function(value, key, section) {
    var sectionContainer = $("." + section);
    var option = sectionContainer.find('[data-checkout-option="' + key + '"]');
    var checkbox = option.find('*[value="on"]');
    if (value.visible === false) {
      option.addClass(hideClass);
      _self._uncheckHideOptionCheckBox(checkbox);
    } else if (value.visible === true) {
      _self._showOption(checkbox);
    }
  },

  /**
   *
   * @param data
   * @param section
   */
  applyDependencies: function(data, section) {
    var sectionContainer = $("." + section);

    /**
     * Option-Handling (first level)
     */
    $.each(data, function(key, value) {
      var radio = sectionContainer.find('*[value="' + key + '"]'),
        subOptions = sectionContainer.find('[data-sub-option="' + key + '"]');

      if (value.visible === false) {
        subOptions.addClass(hideClass);
        _self._uncheckHideOption(radio);
      } else if (value.visible === true) {
        // If Apple Pay is available show the option
        if (
          key !== "APPLEPAY" ||
          (key === "APPLEPAY" && applepay.applePayAvailable())
        ) {
          _self._showOption(radio);
        }
      }

      if (value.forceSelection === true) {
        _self._autoSelectOption(radio);
      }

      if (value.displayValue) {
        _self._setOptionFee(key, value.displayValue, sectionContainer);
      }

      /**
       *  Suboption Handling (second level)
       */
      if (value.options) {
        $.each(value.options, function(subkey, subvalue) {
          var subRadio = subOptions.find('[value="' + subkey + '"]');

          _self._setOptionFee(subkey, subvalue.displayValue, subOptions);

          if (subvalue.visible === false) {
            _self._uncheckHideOption(subRadio);
          } else if (subvalue.visible === true) {
            _self._showOption(subRadio);
          }

          if (subvalue.forceSelection === true) {
            _self._autoSelectOption(subRadio);
          }
        });
      }
    });
  },

  fetchingScreen: function(state, blockType) {
    var summaryBox = $('[data-checkout-block="summary"]'),
      boxFetchingClass = "box-fetching",
      formFetchingClass = "initial-hide";
    summaryBox.addClass(boxFetchingClass);

    if (state && blockType) {
      $(blockType)
        .nextAll(BLOCK_IDENTIFIER)
        .not('[data-checkout-block="payment"]')
        .addClass(boxFetchingClass);
      $('button[type="submit"]').prop("disabled", true);
    } else if (state) {
      form.addClass(formFetchingClass);
    }

    if (state === false) {
      $('button[type="submit"]').prop("disabled", false);
      form.removeClass(formFetchingClass);
      summaryBox.removeClass(boxFetchingClass);
      $(BLOCK_IDENTIFIER).removeClass(boxFetchingClass);
    }
  },

  removeVoucherCode: function(button) {
    var url = $(button).attr("data-target");
    var scout_voucher = url.includes("scout");
    var suomen_voucher = url.includes("suomen");
    $.ajax({
      url: url,
      method: scout_voucher || suomen_voucher ? "DELETE" : "POST",
      headers: global.headers,
      data: form.serialize(),
      beforeSend: function() {
        $(button)
          .addClass("animate")
          .prop("disabled", true);
      },
      success: function(response) {
        var empty = "";
        $(".remote-feedback-message").html(empty);
        _self.requestDependencies(true);
        $(button)
          .removeClass("animate")
          .prop("disabled", false);
        if (scout_voucher) {
          let scoutId = $("#scoutId");
          $("#scout-membership span").removeClass("checked");
          scoutId.prop("disabled", true);
          scoutId.val(empty);
        } else if (suomen_voucher) {
          let suomenId = $("#suomenId");
          $("#suomen-membership span").removeClass("checked");
          suomenId.prop("disabled", true);
          suomenId.val(empty);
        }
      },
      error: function(e) {
        console.log(e.message);
        $(button)
          .removeClass("animate")
          .prop("disabled", false);
        return false;
      }
    });
  }
};
export default dependencies;
