import global from "../global";
import minicart from "../modules/minicart";
import datalayer from "../modules/datalayer";
import validate from "../modules/validate";
import imagezoom from "../modules/imagezoom";
import insurance from "../modules/insurance";
import anchor from "../modules/anchor";
import lazyload from "../modules/lazyload";
import "../modules/recaptcha";
import "../plugin/snsslider";
import "../plugin/snsglossarhover";
import "../plugin/lightcase";
import "../utils/equalizeheights";
import "../plugin/tooltips";
import "../plugin/watchlistlayer";
import "../utils/selector";
import "../utils/imagesLoaded";
import "../plugin/threesixty";

function ADS() {
  this._this = this;
  this.adsCtx = ".article-detail-context";
  this.quickViewCtx = ".quickview-context";
}

ADS.prototype = {
  init: function() {
    const _this = this;
    const ctx = {
      view: "ads"
    };

    $(document).on("click", ".article-detail-context .add-to-cart", function(
      e
    ) {
      e.preventDefault();
      _this.fireDataLayer($(this).data("item-id"));
      var form = $("#article-form");
      _this.submit(form);
    });

    $(document).on(
      "click",
      ".article-detail-context .js-variation-item",
      function(e) {
        e.preventDefault();
        if ($(this).hasClass("is-active")) {
          return;
        }
        _this.toggleSelection($(this), ctx);
        _this.changeVariation($(this), ctx);
      }
    );

    $(document).on("click", ".js-jump-to", function() {
      const target = $(this).attr("data-jump-to");
      $("body,html").animate(
        {
          scrollTop: $(target).offset().top
        },
        200
      );
    });

    _this.unveilImages();
    _this.initColorVariations(ctx);
    _this.initGlossar(ctx);
    _this.initShowCase(ctx);
    _this.initWatchlist(ctx);
    _this.initContactForm();
    _this.threesixtyImage();
    _this.mobileSizeTransformer();
    _this.handleProductTabs();
    _this.sizeGuideZoom();
    _this.initReadingSample();
    _this.setYoutubeTitle();
    _this.priceInformationPopup();
    _this.initFitFinderPdpData();
  },

  unveilImages: function() {
    $(".js-unveil-thumb").unveil();
  },

  setYoutubeTitle: function() {
    const dataList = $(".js-youtube-embed");

    for (let i = dataList.length; i--; ) {
      const youtubeId = $(dataList[i]).attr("data-youtubeId");
      const url = "https://www.youtube.com/watch?v=" + youtubeId;
      $.getJSON(
        "https://noembed.com/embed",
        { format: "json", url: url },
        function(data) {
          const videoElementDesktop = document.getElementById(
            "video-title-" + youtubeId
          );
          const videoElementMobile = document.getElementById(
            "video-mobile-title-" + youtubeId
          );
          if (data.title !== undefined) {
            const titleMaxLength = 45;
            const trimmedTitle =
              data.title.substring(0, titleMaxLength) + "...";
            videoElementDesktop.innerHTML = trimmedTitle;
            videoElementMobile.innerHTML = trimmedTitle;
          } else {
            if (videoElementDesktop !== null && videoElementMobile !== null) {
              videoElementDesktop.parentNode.parentNode.parentNode.remove();
              videoElementMobile.parentNode.parentNode.parentNode.remove();
            }
          }
        }
      );
    }
  },

  priceInformationPopup: function() {
    function showPriceInfoPopup() {
      const priceInfoPopup = document.getElementById("priceInfoPopup");
      const priceInfoPopupOverlay = document.getElementById(
        "priceInfoPopupOverlay"
      );
      if (priceInfoPopup !== null) {
        priceInfoPopup.style.display = "block";
      }
      if (priceInfoPopupOverlay != null) {
        priceInfoPopupOverlay.style.display = "block";
      }
    }

    function closePriceInfoPopup() {
      const priceInfoPopup = document.getElementById("priceInfoPopup");
      const priceInfoPopupOverlay = document.getElementById(
        "priceInfoPopupOverlay"
      );
      if (priceInfoPopup !== null) {
        priceInfoPopup.style.display = "none";
      }
      if (priceInfoPopupOverlay != null) {
        priceInfoPopupOverlay.style.display = "none";
      }
      document.getElementById("overlay").classList.remove("open");
    }

    const priceInfoElement = document.getElementById("priceInfoElement");
    if (priceInfoElement !== null) {
      priceInfoElement.addEventListener("click", function(event) {
        event.preventDefault();
        showPriceInfoPopup();
      });
    }
    const saveInfoElement = document.getElementById("saveInfoElement");
    if (saveInfoElement !== null) {
      saveInfoElement.addEventListener("click", function(event) {
        event.preventDefault();
        showPriceInfoPopup();
      });
    }
    const closeButton = document.getElementById("closeButton");
    if (closeButton !== null) {
      closeButton.addEventListener("click", function() {
        closePriceInfoPopup();
      });
    }
    const priceInfoPopupOverlay = document.getElementById(
      "priceInfoPopupOverlay"
    );
    if (priceInfoPopupOverlay !== null) {
      priceInfoPopupOverlay.addEventListener("click", function() {
        closePriceInfoPopup();
      });
    }
  },

  initFitFinderPdpData: function() {
    function createPdpDataObj() {
      let fitFinderElement = document.getElementById("fitFinderPdpData");
      if (fitFinderElement) {
        let completeData = fitFinderElement.dataset.tracking;
        let currentSize = fitFinderElement.dataset.currentsize;
        let trackingData = JSON.parse(completeData);
        let userData = {
          id: trackingData.userId === "" ? null : trackingData.userId,
          hasLoggedIn: !!trackingData.userId
        };
        window.fitAnalyticsData = {
          shopLanguage: trackingData.shopLanguage,
          shopCountry: trackingData.shopCountry.toUpperCase(),
          mainImageLink: trackingData.mainImageLink,
          user: userData,
          currentItemSubgroupId: trackingData.currentItemSubgroupId,
          allItemSubgroupIds: trackingData.allItemSubgroupIds,
          shopSessionId: trackingData.shopSessionId,
          sizes: trackingData.sizes,
          operations: {
            addToCart: function(currentItemSubgroupId, size) {
              let sizemap = trackingData.sizeMap;
              if (sizemap[currentItemSubgroupId]) {
                let sizeToSkuMapping = sizemap[currentItemSubgroupId];
                let addToCartButton = document.querySelector(".js-add-to-cart");
                if (addToCartButton) {
                  if (sizeToSkuMapping[size]) {
                    let sku = "GLR3_" + sizeToSkuMapping[size];
                    let initialSku = addToCartButton.dataset.itemId;
                    addToCartButton.dataset.itemId = sku;
                    addToCartButton.parentElement.children.itemId.value = sku;

                    addToCartButton.click();

                    addToCartButton.dataset.itemId = initialSku;
                    addToCartButton.parentElement.children.itemId.value = initialSku;
                    return true;
                  }
                }
                return false;
              }
            },
            getSizeChartLink: function() {
              const sizeChartButton = document.getElementById(
                "sizeChartButton"
              );
              if (sizeChartButton) {
                return sizeChartButton;
              } else {
                return document.createElement("div");
              }
            },

            getCartItems: async function() {
              let cartItems = [];
              var ajaxUrl =
                $("#trackingDataLayer").data("base-path") + "ajax/basket/";
              const jsonObject = await $.ajax({
                url: ajaxUrl,
                cache: false,
                method: "GET"
              });
              if (false !== jsonObject) {
                var cartData = jsonObject.cartData.cart;
                let itemList = cartData.products;

                for (let i = 0; i < itemList.length; i++) {
                  let itemSubgroupId =
                    itemList[i].productNr +
                    "_" +
                    itemList[i].color.replaceAll(" ", "_");
                  let size = itemList[i].size;

                  var itemJson = {
                    itemSubgroupId: itemSubgroupId,
                    size: size
                  };
                  cartItems.push(itemJson);
                }
              }

              return cartItems;
            },
            getAddToCartBtn: function() {
              return document.querySelector(".add-to-cart");
            },
            selectSize: function(currentItemSubgroupId, size) {
              let sizemap = trackingData.sizeMap;
              if (sizemap[currentItemSubgroupId]) {
                let sizeToSkuMapping = sizemap[currentItemSubgroupId];
                if (sizeToSkuMapping[size]) {
                  let sizeElementList = document.querySelector(
                    ".se-product-size-switch-div"
                  ).children;
                  for (let i = 0; i < sizeElementList.length; i++) {
                    let element = sizeElementList[i];
                    let sizeValue = element.querySelector("input").value;
                    if (sizeValue === size) {
                      element.click();
                      return true;
                    }
                  }
                  return false;
                }
              }
            },
            getCurrentSize: function() {
              return currentSize.toString();
            }
          }
        };
      }
    }

    createPdpDataObj();
  },

  threesixtyImage: function() {
    $(".js_roundview-image").click(function(event) {
      event.preventDefault();
      let threeSixtyWidth = window.innerHeight;
      let threeSixtyHeight = window.innerHeight;
      let threeSixtyMethod = "click";
      if (window.outerWidth <= 802) {
        threeSixtyWidth = window.innerWidth;
        threeSixtyHeight = window.outerWidth;
        threeSixtyMethod = "auto";
      }
      lightcase.start({
        href: "#",
        maxWidth: threeSixtyWidth,
        maxHeight: threeSixtyHeight,
        inline: {
          width: threeSixtyWidth,
          height: threeSixtyHeight
        },
        shrinkFactor: 1,
        onFinish: {
          injectContent: function() {
            const content = $("<img />").attr("id", "threesixty");
            content.css({
              position: "relative",
              top: 0,
              left: 0,
              cursor: "all-scroll",
              width: threeSixtyWidth,
              margin: "0 auto",
              display: "block"
            });
            content.addClass("round-view-image");

            lightcase
              .get("contentInner")
              .children()
              .html(content);
            $("#threesixty").threesixty({
              images: $(document)
                .find("[data-round-images]")
                .attr("data-round-images")
                .split(","),
              method: threeSixtyMethod,
              autoscrollspeed: 250,
              direction: "forward",
              sensibility: 1
            });
            lightcase.resize();
          }
        }
      });
    });
  },

  sizeGuideZoom: function() {
    // Get the modal
    const sizeGuideZoomWrap = document.getElementById("sizeGuideZoomWrap");
    const sizeGuideZoomWrapMobile = document.getElementById(
      "sizeGuideZoomWrapMobile"
    );
    if (sizeGuideZoomWrap !== null) {
      // Get the image and insert it inside the modal - use its "alt" text as a caption
      const sizeGuideImg = document.getElementById("sizeGuide");
      const sizeGuideImgMobile = document.getElementById("sizeGuideMobile");
      const sizeGuideZoomImg = document.getElementById("sizeGuideZoom");
      const sizeGuideZoomImgMobile = document.getElementById(
        "sizeGuideZoomMobile"
      );
      const captionText = document.getElementById("sizeGuideCaption");
      const captionTextMobile = document.getElementById(
        "sizeGuideMobileCaption"
      );
      sizeGuideImg.onclick = function() {
        sizeGuideZoomWrap.style.display = "block";
        sizeGuideZoomImg.src = this.src;
        sizeGuideZoomImg.alt = this.alt;
        captionText.innerHTML = this.alt;
      };
      sizeGuideImgMobile.onclick = function() {
        sizeGuideZoomWrapMobile.style.display = "block";
        sizeGuideZoomImgMobile.src = this.src;
        sizeGuideZoomImgMobile.alt = this.alt;
        captionTextMobile.innerHTML = this.alt;
      };

      // When the user clicks on <span> (x), close the modal
      sizeGuideZoomWrap.onclick = function() {
        sizeGuideZoomImg.className += " out";
        setTimeout(function() {
          sizeGuideZoomWrap.style.display = "none";
          sizeGuideZoomImg.className = "modal-content";
        }, 400);
      };
      sizeGuideZoomWrapMobile.onclick = function() {
        sizeGuideZoomImgMobile.className += " out";
        setTimeout(function() {
          sizeGuideZoomWrapMobile.style.display = "none";
          sizeGuideZoomImgMobile.className = "modal-content";
        }, 400);
      };
    }
  },

  initWatchlist: function(ctx) {
    const ctxContainer = this._this.ctxResolver(ctx);
    $(ctxContainer)
      .find(".js_add-to-watchlist")
      .SnsWatchlistLayer();
    $(ctxContainer)
      .find(".js_add-to-watchlist.help-popup-wrap_just-hover")
      .SnsTooltips({
        fallbackTill: 0
      });
  },

  initToolTips: function(ctx) {
    const ctxContainer = this._this.ctxResolver(ctx);
    $(ctxContainer)
      .find(".help-popup-wrap, .help-popup-icon")
      .SnsTooltips();
    $(ctxContainer)
      .find(".js-quickview-store-help")
      .SnsTooltips({
        clickable: true,
        positionOrder: ["left"]
      });
  },

  initGlossar: function() {
    $(this.adsCtx + " .js_info-hover").SnsGlossarHover();
  },

  initShowCase: function() {
    $("a[data-rel=lightcase]").lightcase({
      onFinish: {
        /**
         * Event for external scripts e.g. YouTube-Video-Tracking
         */
        eventTrigger: function() {
          $(document).trigger("showCaseLoaded");
        }
      },
      labels: {
        "sequenceInfo.of": "/"
      }
    });
  },

  initContactForm: function() {
    $(document).on("submit", "#contactForm", function(event) {
      event.preventDefault();
      const $form = $(this);
      $.ajax({
        type: "POST",
        headers: global.headers,
        url: $form.attr("action"),
        data: $form.serialize(),
        success: function(response) {
          const $contactFormContent = $("#contactFormContent");
          $contactFormContent.html(response);
          if ($contactFormContent.find("#contactFormSuccess").length) {
            $(".js-contactButton").addClass("disabled");
          } else {
            $contactFormContent.find(".selector").SnsSelector();
            $contactFormContent.find(".js-design-checkbox").SnsDesignCheckbox();
            const $captchaError = $(".contact-captcha-err").find(".error-txt");
            $captchaError.html($captchaError.attr("data-err-msg"));
            if (typeof grecaptcha !== undefined) {
              grecaptcha.render("contactCaptcha", {
                sitekey: $(".g-recaptcha").attr("data-sitekey")
              });
            }
            validate.init();
          }
        },
        error: function() {
          return false;
        }
      });
    });
  },

  fireDataLayer: function(itemId) {
    datalayer.addToCart(itemId, 1, false);
  },

  submit: function(form) {
    if ($(form).hasClass("isGiftcard")) {
      if (
        $(form)
          .parsley()
          .validate() === false
      ) {
        $(form)
          .parsley()
          .validate();
        return false;
      }
    }

    lightcase.start({
      href: $(form).attr("data-action"),
      showSequenceInfo: false,
      type: "ajax",
      ajax: {
        width: 700,
        data: $(form).serialize()
      },
      maxWidth: 700,
      maxHeight: 1100,
      shrinkFactor: 0.99,
      onInit: {
        disableButton: function() {
          $(form)
            .find('button[type="submit"]')
            .prop("disabled", true);
        }
      },
      onFinish: {
        updateView: function() {
          lightcase
            .get("contentInner")
            .children()
            .FenixImagesLoaded()
            .then(function() {
              const miniCartData = lightcase
                .get("contentInner")
                .children()
                .find("#addToCartLayer")
                .data();
              if (miniCartData) {
                minicart.reset();
                minicart._fetchMetadata();
              }
              lightcase.resize();
              lightcase.get("contentInner").css({
                opacity: 1
              });
            })
            .then(() => {
              $(document).trigger("addToCartLayer.ready", {
                element: lightcase
                  .get("contentInner")
                  .children()
                  .find("#addToCartLayer")
              });
              let insuranceChecked = insurance.getInsuranceCheckboxValue();
              insurance.setInsuranceCheckboxDisable(insuranceChecked);
            });
        }
      },
      onClose: {
        enableButton: function() {
          $(form)
            .find('button[type="submit"]')
            .prop("disabled", false);
        },
        abortRunningXHR: function() {
          try {
            lightcase.getObjectData().pendingRequest.abort();
          } catch (err) {
            console.log(err);
          }
        }
      }
    });
  },

  // build dropdown for mobile based on desktop selector
  mobileSizeTransformer: function() {
    let sizeList = $(".size-menu li input");
    // Create dropdown base
    // Populate dropdown with menu items
    sizeList.each(function() {
      let el = $(this);
      if (el.hasClass("is-active")) {
        $(".mobile-size-select").html(el.val());
      }
    });
    // To update pdp with selected variation dropdown
    $(".size-menu select").change(function() {
      let selectedOption = $(this)
        .find("option:selected")
        .val();
      sizeList.each(function() {
        let el = $(this);
        if (el.val() === selectedOption) {
          el.parent().click();
        }
      });
    });

    //click events to show/hide the mobile overlay
    $(".mobile-size-drop-wrap").click(function() {
      $(".mobile-size-overlay").removeClass("hide");
    });

    $(".close-size-overlay").click(function() {
      $(".mobile-size-overlay").addClass("hide");
    });
  },

  // toggle variation selection
  toggleSelection: function($target, ctx) {
    const _this = this;
    const ctxContainer = _this.ctxResolver(ctx);
    const productOption = $(ctxContainer).find(".js-product-option");

    $target
      .closest(productOption)
      .find(".js-variation-item")
      .each(function() {
        $(this).removeClass("is-active");
        //there is only an input on DIM1 if DIM1 != color, but we have to treat this case as well
        $(this)
          .find("input")
          .removeClass("is-active");
      });
    $target.addClass("is-active");
    //there is only an input on DIM1 if DIM1 != color, but we have to treat this case as well
    $target.find("input").addClass("is-active");
  },

  // variation change
  changeVariation: function($target, ctx) {
    const _this = this;
    const dim1WasClicked = $target.hasClass("js_isMainDim");
    const ctxContainer = _this.ctxResolver(ctx);
    const $variations = $(ctxContainer).find(".product-base-information");

    const productId = $variations.attr("data-productId");
    // no prefixes
    const productNumber = $variations.attr("data-product-number");
    let variation_params = "productId" + "=" + productId + "&";
    const $mainDim_input = $variations.find(".js_isMainDim.is-active");
    const $subDim_input = $variations.find(".js_isSubDim.is-active");
    // add DIM 1 to variation_params
    if ($mainDim_input.length !== 0) {
      variation_params +=
        $mainDim_input.attr("data-name") +
        "=" +
        $mainDim_input.attr("data-value") +
        "&";
    }
    // add DIM 2 to variation_params
    if ($subDim_input.length !== 0) {
      variation_params +=
        $subDim_input.attr("data-name") +
        "=" +
        $subDim_input.attr("data-value") +
        "&";
    }

    const variation_url =
      $variations.attr("data-action") + "?" + variation_params;
    const lastSelected = document
      .querySelectorAll(".tab-contents.active")[0]
      .getAttribute("data-content");

    let colors = document.querySelector(".color-variations .colors");
    const allColorsShown = colors && colors.classList.contains("showAll");
    let insuranceChecked = insurance.getInsuranceCheckboxValue();

    $.ajax({
      url: variation_url,
      dim1WasClicked: dim1WasClicked,
      success: function(data) {
        const $data = $(data);
        const ads_topContainer = $(".art-detail-page-top");
        if (ctx.view === "ads") {
          ads_topContainer.replaceWith($data);
          const variationsWrapper = $data.find(".product-base-information");
          const itemUrl = variationsWrapper.attr("data-item-url");
          _this.clickOnProductVariation(itemUrl);
          if (itemUrl) {
            //replace url without reload page
            window.history.replaceState({}, document.title, itemUrl);
          }
        } else if (ctx.view === "quickview") {
          const quickviewLightcase = $("#lightcase-case");
          quickviewLightcase.find(ads_topContainer).html($data);
          quickviewLightcase
            .find(ads_topContainer)
            .FenixImagesLoaded()
            .then(function() {
              lightcase.resize();
            });
        }

        _this.initColorVariations(ctx, allColorsShown);
        _this.initGlossar(ctx);
        _this.initToolTips(ctx);
        _this.initWatchlist(ctx);
        _this.initShowCase(ctx);
        _this.threesixtyImage(ctx);
        imagezoom.init(ctx);
        imagezoom.quickviewImageSwipe();
        _this.unveilImages();
        _this.mobileSizeTransformer();
        global.contentToggle();
        _this.handleProductTabs(lastSelected);
        _this.sizeGuideZoom();
        _this.setYoutubeTitle();
        anchor.initAnchor();
        if (typeof insuranceChecked !== "undefined") {
          $(".js-design-checkbox").SnsDesignCheckbox();
          insurance.toggleInsuranceCheckbox(insuranceChecked);
          insurance.initInsuranceCheckboxDisabled();
          insurance.initInsuranceHintLayer();
        }
        _this.priceInformationPopup();
        _this.initFitFinderPdpData();

        var dimension = this.dim1WasClicked ? "DIM1" : "DIM2";

        $(document).trigger("variationChanged", {
          productId: productNumber,
          dimension: dimension //DIM1 == VBM1 == e.g. color, DIM2 == VBM2 == e.g. size
        });
      },
      error: function(error) {
        if (window.console) {
          window.console.log(error);
        }
      }
    });
  },

  clickOnProductVariation: function(itemUrl) {
    const skuAttrnamePosition = itemUrl.split("sku=");
    const sku = skuAttrnamePosition[1];
    const ajaxUrl =
      window.iShop.config.baseUrl +
      "/datalayer/json/product-variation/sku/" +
      sku +
      "/position/" +
      0;
    $.ajax({
      url: ajaxUrl,
      success: function(jsonData) {
        dataLayer.push(jsonData);
        lazyload.initLazySVGs();
        lazyload.initLazyIMGs();
      },
      error: function(event) {
        if (window.console) {
          window.console.log(event);
        }
      }
    });
  },

  handleProductTabs: function(lastSelected) {
    const coll = document.querySelectorAll(".product-infotab-wrap")[0]
      .childNodes;

    let prevId = "." + coll[1].id;
    if (lastSelected === undefined || null) {
      $(".tab-desc").addClass("active");
      $("#tab-desc")
        .find(".selected")
        .addClass("active");
    } else {
      const cssSelector = "." + lastSelected;
      const idSelector = "#" + lastSelected;
      prevId = cssSelector;
      $(cssSelector).addClass("active");
      $(idSelector)
        .find(".selected")
        .addClass("active");
    }

    for (let i = 0; i < coll.length; i++) {
      coll[i].onclick = function() {
        const id = "." + this.getAttribute("id");
        const id2 = "#" + this.getAttribute("id");
        if (prevId !== id) {
          $(".product-info-tab")
            .find(".selected")
            .removeClass("active");
          if (prevId === id) {
            $(id).addClass("active");
            $(id2)
              .find(".selected")
              .addClass("active");
          } else {
            $(prevId).removeClass("active");
            $(id).addClass("active");
            $(id2)
              .find(".selected")
              .addClass("active");
          }
          prevId = id;
        }
      };
    }
  },

  ctxResolver: function(ctx) {
    if (ctx.view === "ads") {
      return this.adsCtx;
    } else if (ctx.view === "quickview") {
      return this.quickViewCtx;
    }
  },

  initReadingSample: function() {
    // Initialize reading sample layer
    // See: https://www.blickinsbuch.de/gateway/info/globetrotter-20211216-101542.php
    window.midvox = (function(d, s, id) {
      let js,
        as = d.getElementsByTagName(s),
        fjs = as[0],
        o = as[as.length - 1],
        p = "midvox_" + Math.round(Math.random() * 65536),
        t = window.midvox || {};
      o.setAttribute("id", p);
      let init = [
        "9017364122",
        $(".reading-sample-wrapper").data("isbn"),
        1,
        2
      ];
      let item = { init: init };
      item.setScriptMark = p;
      item.setCntId = "2705464745";
      item.setLang = "de";
      item.setElement = "div";
      item.setElementClass = "reading-sample-button";
      item.setIncludeDiv = "reading-sample-button-wrapper";

      if (!t.queue) t.queue = [];
      t.queue.push(item);
      if (d.getElementById(id)) {
        return t;
      }
      js = d.createElement(s);
      js.id = id;
      js.async = "true";
      js.src = "//appjs.blickinsbuch.de/gateway/app.js";
      fjs.parentNode.insertBefore(js, fjs);
      return t;
    })(document, "script", "midvox_async");

    // Try to show reading sample
    $(document).ready(function() {
      let counter = 0,
        singleTimeout = 100,
        attempts = 30,
        visible = false;
      let interval = setInterval(function() {
        if ($(".reading-sample-button").length) {
          $(".reading-sample-wrapper").removeClass("hide");
          visible = true;
        }
        if (counter > attempts || visible) {
          clearInterval(interval);
        }
        counter++;
      }, singleTimeout);
    });
  },

  initColorVariations: function(ctx, showAllColorsAfterVariationChanged) {
    let overlay = document.querySelector(
      ".js-color-variation-modal-trigger-overlay"
    );
    if (!overlay) {
      return;
    }

    overlay.addEventListener("click", toggleShowAllColors);

    window.removeEventListener("resize", updateColorVariations, true);
    window.addEventListener("resize", updateColorVariations, true);

    updateColorVariations();

    if (showAllColorsAfterVariationChanged) {
      toggleShowAllColors("showAll");
    }
  }
};

function updateColorVariations() {
  let colors = document.querySelector(".color-variations .colors");

  if (colors.classList.contains("showAll")) {
    return;
  }

  const colorElements = colors.querySelectorAll(".item");

  let parentRight =
    colors.getBoundingClientRect().right +
    parseFloat(getComputedStyle(colors).marginRight);

  const cssStyleDeclaration = getComputedStyle(colorElements.item(0));
  const marginLeft = parseFloat(cssStyleDeclaration.marginLeft);
  const marginRight = parseFloat(cssStyleDeclaration.marginRight);

  colorElements.forEach(colorElement =>
    colorElement.classList.remove("hidden")
  );

  for (let i = colorElements.length; --i >= 0; ) {
    const colorElement = colorElements[i];

    if (colorElement.classList.contains("is-active")) {
      parentRight -=
        marginLeft + colorElement.getBoundingClientRect().width + marginRight;
      continue;
    }

    if (
      colorElement.getBoundingClientRect().right + marginRight >
      parentRight
    ) {
      colorElement.classList.add("hidden");
    }
  }

  const overlay = document.querySelector(
    ".js-color-variation-modal-trigger-overlay"
  );
  overlay.classList.remove("shown");

  const hiddenColorElementCount = colors.querySelectorAll(".item.hidden")
    .length;
  if (hiddenColorElementCount === 0) {
    return;
  }

  overlay.querySelector(
    ".hiddenElementCount"
  ).innerText = hiddenColorElementCount;

  const visibleElements = colors.querySelectorAll(".item:not(.hidden)");
  const lastVisibleColorElement = visibleElements.item(
    visibleElements.length - 1
  );

  const left =
    lastVisibleColorElement.getBoundingClientRect().left -
    lastVisibleColorElement.offsetParent.getBoundingClientRect().left -
    parseFloat(getComputedStyle(lastVisibleColorElement).marginLeft);
  overlay.style.left = left + "px";

  overlay.classList.add("shown");
}

function toggleShowAllColors() {
  let colors = document.querySelector(".color-variations .colors");

  colors.classList.toggle("showAll");

  if (!colors.classList.contains("showAll")) {
    updateColorVariations();
  } else {
    const overlay = document.querySelector(
      ".js-color-variation-modal-trigger-overlay"
    );
    overlay.style.left = "0";
  }
}

export default new ADS();
