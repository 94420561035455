import global from "../global";

const form = document.getElementById("packlistForm"),
  $form = $(form);

export default {
  init: function() {
    let self = this;
    self._initPacklistChangeListener();
  },
  _initPacklistChangeListener: function() {
    let _this = this;

    $form.on("change", function() {
      console.log("FormChange detected.");
      let json = _this._createJson();
      console.log(json);
      $.ajax({
        type: "POST",
        headers: global.headers,
        url: $form.attr("action"),
        data: json,
        dataType: "json",
        contentType: "application/json; charset=utf-8"
      }).always(function(json) {});
    });
  },
  _createJson: function() {
    let params = [];
    let formData = $("form input:checkbox");
    for (const formDatum of formData) {
      if ($(`input[id="${formDatum.id}"]:checked`).length > 0) {
        if (!params[formDatum.id]) {
          params.push(formDatum.id);
        }
      }
    }
    return JSON.stringify(params);
  }
};
