import "../lib/jquery.unveil";

function Lazyload() {
  this.defaults = {
    threshold: 100,
    selector: ".js-unveil",
    callback: ""
  };
}

Lazyload.prototype = {
  init: function(data) {
    if (!$.fn.unveil) {
      return;
    }
    var myData = $.extend(this.defaults, data);
    $(myData.selector).unveil(myData.threshold, function() {
      $(this).load(function() {
        this.style.opacity = 1;
      });
    });

    this.initLazyIMGs();
    this.initLazySVGs();
  },

  initLazyIMGs: function() {
    const lazyIMGs = document.querySelectorAll("img.lazy-img");

    if ("IntersectionObserver" in window) {
      const options = {
        root: null, // Standard: Das Browser-Fenster
        rootMargin: "0px 0px 100px 0px", // Bereich vor dem sichtbaren Bereich
        threshold: 0.1 // Mindestens 10% des Elements müssen sichtbar sein
      };

      const imgObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const img = entry.target;
            const originalSrc = img.dataset.src;

            if (originalSrc) {
              img.setAttribute("src", originalSrc);
              img.removeAttribute("data-src");
              img.classList.remove("lazy-img");
              observer.unobserve(img);
            }
          }
        });
      }, options);
      lazyIMGs.forEach(img => {
        imgObserver.observe(img);
      });
    } else {
      // Fallback: Alle IMGs sofort laden
      lazyIMGs.forEach(img => {
        const originalSrc = img.dataset.src;
        if (originalSrc) {
          img.setAttribute("xlink:href", originalSrc);
          img.removeAttribute("data-href");
        }
      });
    }
  },

  initLazySVGs: function() {
    const lazySVGs = document.querySelectorAll("svg.lazy-svg");

    if ("IntersectionObserver" in window) {
      const options = {
        root: null, // Standard: Das Browser-Fenster
        rootMargin: "0px 0px 100px 0px", // Bereich vor dem sichtbaren Bereich
        threshold: 0.1 // Mindestens 10% des Elements müssen sichtbar sein
      };

      const svgObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            const svg = entry.target;
            const useElement = svg.querySelector("use");
            const originalHref = useElement.dataset.href;

            if (originalHref) {
              useElement.setAttribute("xlink:href", originalHref);
              useElement.removeAttribute("data-href");
              svg.classList.remove("lazy-svg");
              observer.unobserve(svg);
            }
          }
        });
      }, options);

      lazySVGs.forEach(svg => {
        svgObserver.observe(svg);
      });
    } else {
      // Fallback: Alle SVGs sofort laden
      lazySVGs.forEach(svg => {
        const useElement = svg.querySelector("use");
        const originalHref = useElement.dataset.href;
        if (originalHref) {
          useElement.setAttribute("xlink:href", originalHref);
          useElement.removeAttribute("data-href");
        }
      });
    }
  }
};
export default new Lazyload();
