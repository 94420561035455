const init = function() {
  for (const greenerChoiceTeaser of document.querySelectorAll(
    ".greener-choice-teaser"
  )) {
    if (greenerChoiceTeaser.getAttribute("inited")) {
      continue;
    }

    const toggles = greenerChoiceTeaser.querySelectorAll(
      "ul.unordered-list li > h3"
    );
    let openedElement;

    for (const toggle of toggles) {
      const onTransitionEnd = () => {
        openedElement.nextElementSibling.removeEventListener(
          "transitionend",
          onTransitionEnd
        );

        const content = openedElement.nextElementSibling.firstElementChild;
        content.style.overflow = "visible";
      };

      const onClick = event => {
        if (openedElement) {
          const content = openedElement.nextElementSibling.firstElementChild;
          content.style.overflow = "hidden";
          openedElement.classList.remove("open");
        }

        if (openedElement === event.currentTarget) {
          openedElement = undefined;
          return;
        }

        openedElement = event.currentTarget;

        if (openedElement) {
          openedElement.nextElementSibling.addEventListener(
            "transitionend",
            onTransitionEnd
          );
          openedElement.classList.add("open");
        }
      };

      toggle.addEventListener("click", onClick);
    }

    const bpMmin = getComputedStyle(document.documentElement).getPropertyValue(
      "--bp-m-min"
    );
    const mediaQueryList = matchMedia(`screen and (min-width: ${bpMmin})`);
    const onChange = event => {
      const logo = greenerChoiceTeaser.querySelector(":scope > .image");
      if (event.matches) {
        if (
          typeof greenerChoiceTeaser.dataset.backgroundImageD !== "undefined"
        ) {
          greenerChoiceTeaser.style.backgroundImage = `url(${greenerChoiceTeaser.dataset.backgroundImageD})`;
        }
        if (typeof logo.dataset.greenerChoiceLogoD !== "undefined") {
          logo.style.backgroundImage = `url(${logo.dataset.greenerChoiceLogoD})`;
        }
      } else {
        if (
          typeof greenerChoiceTeaser.dataset.backgroundImageM !== "undefined"
        ) {
          greenerChoiceTeaser.style.backgroundImage = `url(${greenerChoiceTeaser.dataset.backgroundImageM})`;
        }
        if (typeof logo.dataset.greenerChoiceLogoM !== "undefined") {
          logo.style.backgroundImage = `url(${logo.dataset.greenerChoiceLogoM})`;
        }
      }
    };
    mediaQueryList.addEventListener("change", onChange);

    onChange(mediaQueryList);

    greenerChoiceTeaser.setAttribute("inited", "");
  }
};

const closest = document.querySelector(".product-detail-page");
if (closest) {
  $(document).on("variationChanged", init);
}

export default {
  init: init
};
